import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import MainLayout from './MainLayout'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import { connect } from 'react-redux'
import Hero from '../components/Hero/Hero'
import MobileBook from '../components/MobileBook/MobileBook'
import CenterOverlay from '../components/CenterOverlay/CenterOverlay'
import Lead from '../components/Lead/Lead'
import IntroTitle from '../components/IntroTitle/IntroTitle'
import FlexibleContent from '../components/FlexibleContent/FlexibleContent'
import getLanguageCode from '../utils/getLanguageCode'

class ActivityTypeTemplate extends Component {
  componentDidMount () {
    const locale = this.props.pageContext.locale
    const translations = this.props.pageContext.translations
    const langData = { locale, translations }
    this.props.setLangData(langData)
  }

  render () {
    const metaTitle = this.props.data.page.acf.metaTitle
    const metaDescription = this.props.data.page.acf.metaDescription
    const pageTitle = this.props.data.page.title
    const locale = this.props.pageContext.locale
    const heroBackupSourceUrl = this.props.data.page.acf.hero_image.source_url
    const heroImgAltText = this.props.data.page.acf.hero_image.alt_text
    const heroLocalFile = this.props.data.page.acf.hero_image.localFile
    const heroImageSizes = heroLocalFile
      ? heroLocalFile.childImageSharp.fluid
      : null
    const parentPage = this.props.data.parentPage
    const longTitle = this.props.data.page.acf.long_title
    const lead = this.props.data.page.acf.lead
    const bookContent = this.props.data.bookContent
    const bookFormHeader = {
      label: bookContent.room.dropdown.label,
      title: bookContent.room.dropdown.title
    }
    const bookFormLabels =
      bookContent.room.dropdown.content.book_form.book_form_labels
    this.props.data.page.mainHeader = this.props.data.mainHeader
    const languageCode = getLanguageCode(locale)
    const navMenuRight = this.props.data.mainHeader.nav_menu_right
    const phoneLink = navMenuRight.nav.items.find(
      item => item.attr.includes('phone-link')
    )

    const alert = this.props.data.page.acf.alert

    return (
      <div>
        <Helmet htmlAttributes={{ lang: languageCode }}>
          <title>{metaTitle}</title>
          <meta name='description' content={metaDescription} />
          <link
            rel='canonical'
            href={`${process.env.GATSBY_SITE_URL}${this.props.data.page.path}`}
          />
          {this.props.pageContext.translations.map(
            ({ locale_name, path }, i) => {
              return (
                <link
                  key={i}
                  rel='alternate'
                  hreflang={locale_name.replace('_', '-')}
                  href={`${process.env.GATSBY_SITE_URL}${path}`}
                />
              )
            }
          )}
          {this.props.pageContext.translations.map(
            ({ native_name, path }, i) => {
              if (native_name === 'English') {
                return (
                  <link
                    key={i}
                    rel='alternate'
                    hreflang='x-default'
                    href={`${process.env.GATSBY_SITE_URL}${path}`}
                  />
                )
              }
            }
          )}
        </Helmet>
        <MainLayout
          mainHeaderData={this.props.data.mainHeader}
          bookContent={bookContent}
          locale={locale}
          locationKey={this.props.location.key || ''}
          translations={this.props.pageContext.translations}
        >
          <Hero
            backupSourceUrl={heroBackupSourceUrl}
            altText={heroImgAltText}
            imageSizes={heroImageSizes}
            title={pageTitle}
            parentPage={parentPage}
            locale={locale}
            beta
            alert={alert}
          />
          <div>
            <MediaQueryProvider width={1440} height={900}>
              <MediaQuery query='(max-width: 977px)'>
                <MobileBook
                  locale={locale}
                  labels={bookFormLabels}
                  header={bookFormHeader}
                  phoneLink={phoneLink}
                  centerLayout
                />
              </MediaQuery>
            </MediaQueryProvider>
          </div>
          <CenterOverlay>
            <IntroTitle text={longTitle} center gold small />
            <Lead text={lead} centered borderBottom />
            <FlexibleContent
              data={this.props.data.page}
              locale={locale}
              galleryData={this.handleGalleryData}
              centerOverlay
            />
          </CenterOverlay>
        </MainLayout>
      </div>
    )
  }
}

ActivityTypeTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = ({ setLangData }) => {
  return { setLangData }
}

const mapDispatchToProps = dispatch => {
  return {
    setLangData: langData =>
      dispatch({
        type: `LANG_DATA`,
        langData
      })
  }
}

const ConnectedActivityType = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTypeTemplate)

export default ConnectedActivityType

export const pageQuery = graphql`
  query activityTypePageQuery(
    $id: String!
    $wordpressParent: Int!
    $locale: String!
  ) {
    page: wordpressPage(id: { eq: $id }) {
      path
      title
      slug
      acf {
        short_title
        long_title
        hero_image {
          source_url
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 75) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        alert
        lead
        metaTitle: meta_title
        metaDescription: meta_description
      }
      ...FlexibleContentData
    }
    parentPage: wordpressPage(wordpress_id: { eq: $wordpressParent }) {
      wordpress_id
      title
      path
    }
    bookContent: wordpressStickyBook(language_code: { eq: $locale }) {
      ...StickyBookContent
    }
    mainHeader: wordpressMainHeader(language_code: { eq: $locale }) {
      ...MainHeaderContent
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
